<template>
  <div @contextmenu.prevent>
    <el-dialog
      :title="addOrEdit == 'add' ? '新建管线' : '编辑管线'"
      :visible.sync="dialogVisible"
      :inline="true"
      :modal="false"
      :close-on-click-modal="false"
      width="586px"
      custom-class="dialog-class"
      @close="close"
    >
      <div class="form-box">
        <el-form
          ref="form"
          :model="form"
          :rules="rules"
          :inline="true"
          label-position="right"
          label-width="100px"
          class="form"
          size="mini"
        >
          <!-- <el-form-item label="企业名称:">
            <el-input v-model="form.companyName" :style="labelWidthMax" disabled></el-input>
          </el-form-item> -->
          <el-form-item label="所属图层:" prop="pipeCode">
            <el-input
              v-model="form.layerName"
              placeholder="所属图层"
              maxlength="40"
              disabled
              :style="labelWidthMax"
            ></el-input>
          </el-form-item>
          <el-form-item label="管线编号:" prop="pipeCode" v-if="form.id">
            <el-input
              v-model="form.pipeCode"
              placeholder="请输入管线编号"
              maxlength="40"
              disabled
              :style="labelWidthMax"
            ></el-input>
          </el-form-item>
          <el-form-item label="管道名称:" prop="pipeName">
            <el-input
              v-model="form.pipeName"
              placeholder="请输入管道名称"
              :style="labelWidthMax"
              maxlength="40"
            ></el-input>
          </el-form-item>
          <el-form-item label="管材:" prop="pipeType">
            <el-select
              v-model="form.pipeType"
              popper-class="gis-default"
              placeholder="请选择管材"
              :style="labelWidth"
              @change="pipeTypeChange"
            >
              <el-option
                v-for="item in pipeTypeList"
                :key="item.dictCode"
                :label="item.name"
                :value="item.dictCode"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="管材型号:" prop="pipeModel">
            <el-select
              v-model="form.pipeModel"
              popper-class="gis-default"
              placeholder="请选择管材型号"
              clearable
              :style="labelWidth"
            
            >
              <el-option
                v-for="item in pipeModelList"
                :key="item.dictCode"
                :label="item.name"
                :value="item.dictCode"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="公称直径:" prop="diameter">
            <el-select
              v-model="form.diameter"
              popper-class="gis-default"
              placeholder="请选择公称直径"
              :style="labelWidth"
            >
              <el-option
                v-for="item in diameterList"
                :key="item.code+''"
                :label="item.name"
                :value="item.code"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="实际外径(mm):" prop="outerDiameter">
            <el-input
              v-model="form.outerDiameter"
              maxlength="22"
              placeholder="请输入实际外径"
              :style="labelWidth"
            ></el-input>
          </el-form-item>
          <el-form-item label="壁厚(mm):" prop="thickness">
            <el-input
              v-model="form.thickness"
              placeholder="请输入壁厚"
              clearable
              :style="labelWidth"
              maxlength="22"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="实际内径(mm):" prop="innerDiameter">
            <el-input
              v-model="form.innerDiameter"
              maxlength="22"
              placeholder="请输入实际内径"
              :style="labelWidth"
            ></el-input>
          </el-form-item>
          <el-form-item label="粗糙度:" prop="roughness">
            <el-input-number
              v-model="form.roughness"
              :precision="2"
              :step="0.01"
              :max="999999.99"
              :min="0"
              :style="labelWidth"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="埋深(m):" prop="depth">
            <el-input
              v-model="form.depth"
              placeholder="请输入埋深"
              clearable
              :style="labelWidth"
              maxlength="22"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="压力级别:" prop="pressureLevel">
            <el-select
              v-model="form.pressureLevel"
              popper-class="gis-default"
              :style="labelWidth"
              placeholder="请选择压力级别"
            >
              <el-option
                v-for="item in pressureLevelList"
                :key="item.dictCode"
                :label="item.name"
                :value="item.dictCode"
              >
              </el-option>
            </el-select>
          </el-form-item>
           <el-form-item label="设计压力:" >
            <el-input
              v-model="form.designPressure"
              placeholder="请输入设计压力"
              :style="labelWidth"
              maxlength="60"
            ></el-input>
          </el-form-item>
          <el-form-item label="运行压力:" >
            <el-input
              v-model="form.operationPressure"
              placeholder="请输入运行压力"
              :style="labelWidth"
              maxlength="60"
            ></el-input>
          </el-form-item>
          <el-form-item label="管道长度(m):" v-if="lineArr.length<2">
            <el-input
              v-model="pipeLengthTotal"
              disabled
              :style="labelWidth"
              placeholder="请输入管道长度"
            ></el-input>
          </el-form-item>
         
          <el-form-item label="建设日期:" prop="buildDate">
            <el-date-picker
              v-model="form.buildDate"
              type="date"
              :style="labelWidth"
              :clearable="true"
              :editable="false"
              popper-class="datePickerClass"
              value-format="yyyy-MM-dd"
              placeholder="建设日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="燃气介质:" prop="gasType">
            <el-select
              v-model="form.gasType"
              popper-class="gis-default"
              :style="labelWidth"
              placeholder="请选择燃气介质"
            >
              <el-option
                v-for="item in gasTypeList"
                :key="item.dictCode"
                :label="item.name"
                :value="item.dictCode"
              >
              </el-option>
            </el-select>
          </el-form-item>
         
          <el-form-item label="状态:" prop="pipeStatus">
            <el-select
              v-model="form.pipeStatus"
              popper-class="gis-default"
              :style="labelWidth"
              placeholder="请选择状态"
            >
              <el-option
                v-for="item in pipeStatusList"
                :key="item.dictCode"
                :label="item.name"
                :value="item.dictCode"
              >
              </el-option>
            </el-select>
          </el-form-item>
           <el-form-item label="敷设方式:" prop="buildType">
            <el-select
              v-model="form.buildType"
              popper-class="gis-default"
              :style="labelWidth"
              placeholder="请选择敷设方式"
            >
              <el-option
                v-for="item in buildTypeList"
                :key="item.dictCode"
                :label="item.name"
                :value="item.dictCode"
              >
              </el-option>
            </el-select>
          </el-form-item>
          
          <el-form-item label="确认状态:" prop="confirmStatusCode">
            <el-select
              v-model="form.confirmStatusCode"
              popper-class="gis-default"
              :style="labelWidth"
              placeholder="请选择确认状态"
            >
              <el-option
                v-for="item in confirmStatusList"
                :key="item.dictCode"
                :label="item.name"
                :value="item.dictCode"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="下次检测日期:" prop="nextMonitorDate">
             <el-date-picker
              v-model="form.nextMonitorDate"
              type="date"
              :style="labelWidth"
              :clearable="true"
              :editable="false"
              popper-class="datePickerClass"
              value-format="yyyy-MM-dd"
              placeholder="下次检测日期"
            >
            </el-date-picker>
          </el-form-item>
         
          
          <el-form-item label="所在道路:" prop="road">
            <el-input
              v-model="form.road"
              placeholder="请输入所在道路"
              :style="labelWidthMax"
              maxlength="60"
            ></el-input>
          </el-form-item>
          <el-form-item label="建设单位:">
            <el-input
              v-model="form.buildOrganization"
              placeholder="请输入建设单位"
              :style="labelWidthMax"
              maxlength="60"
            ></el-input>
          </el-form-item>
          <el-form-item label="设计单位:">
            <el-input
              v-model="form.designOrganization"
              placeholder="请输入设计单位"
              :style="labelWidthMax"
              maxlength="60"
            ></el-input>
          </el-form-item>
          <el-form-item label="备注:">
            <el-input
              v-model="form.remark"
              type="textarea"
              :rows="2"
              :style="labelWidthMax"
              maxlength="60"
              placeholder="请输入备注"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="btn">
        <el-button size="mini" @click="close">取消</el-button>
        <el-button v-if="addOrEdit == 'add'" type="primary" size="mini" @click="submit"
          >保存</el-button
        >
        <el-button v-else type="primary" size="mini" @click="update">保存</el-button>
       
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { findDictTree,selectDictListByPid} from '../apis/commonType'
import { commonDict, serverMap } from '../utils/dict.js'
import {
  steelThicknessAndBore,
  pe11ThicknessAndBore,
  pe17ThicknessAndBore
} from '../utils/calculateBroe.js'

import { saveLine, pipeUpdate } from '../apis/tool'
import { formatTime } from '../utils/common.js'
import { getCompanyName, getCompanyId } from '../utils/auth'
export default {
  data() {
    return {
      addOrEdit: 'add',
      form: {
        pipeType: '',
        pipeModel: '',
        diameter: '',
        pipeStatus: '',
        pipeName:'',
        pressureLevel:'',
        gasType:'',
        buildDate:'',
        pipeStatus:'',
        buildType:'',
        confirmStatusCode:'',
        nextMonitorDate:'',
        innerDiameter:'',
        outerDiameter:'',
        thickness:''
      },
      labelWidth: 'width:140px',
      labelWidthMax: 'width:405px',
      dialogVisible: false,
      buildTypeList: [],
      pipeTypeList: [],
      pipeModelList: [],
      diameterList: [],
      diameterData: [],
      pressureLevelList: [],
      gasTypeList: [],
      pipeStatusList: [],
      standardList: [],
      antisepticTypeList: [],
      pipeLengthTotal: null,
      lineArr: [],
      confirmStatusList:[],
       endPointObj: {},
      startPointObj: {},
      
      rules: {
        // roughness: [{ required: true, message: '请输入粗糙度', trigger: ['blur', 'change'] }],
        pipeName: [{ required: true, message: '请输入管道名称', trigger: 'blur' }],
        pipeType: [{ required: true, message: '请选择管材', trigger: 'change' }],
        // pipeModel: [{ required: true, message: '请选择管材型号', trigger: 'change' }],
        diameter: [{ required: true, message: '请选择公称直径', trigger: 'change' }],
        pressureLevel: [{ required: true, message: '请选择压力级别', trigger: 'change' }],
        gasType: [{ required: true, message: '请选择燃气介质', trigger: 'change' }],
        // buildDate: [{ required: true, message: '请选择建设日期', trigger: 'change' }],
        pipeStatus: [{ required: true, message: '请选择状态', trigger: 'change' }],
        buildType:[{ required: true, message: '请选择敷设方式', trigger: 'change' }],
        confirmStatusCode:[{ required: true, message: '请选择确认状态', trigger: 'change' }],
        // nextMonitorDate:[{ required: true, message: '下次检测日期', trigger: 'change' }],
        innerDiameter: [
          {
            pattern: /(^[1-9]\d{0,5}?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/,
            message: '数据格式不正确!'
          }
        ],
       outerDiameter: [
          {
            pattern: /(^[1-9]\d{0,5}?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/,
            message: '数据格式不正确!'
          }
        ],
        thickness: [
          {
            pattern: /(^[1-9]\d{0,5}?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/,
            message: '数据格式不正确!'
          }
        ],
        depth: [
          {
            pattern: /(^[1-9]\d{0,5}?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/,
            message: '数据格式不正确!'
          }
        ]
      },
      loadingD:true
    }
  },
  mounted() {},
  methods: {
    getRealBore() {
      // let list = this.diameterList.find(el => el.code == this.form.diameter)
      // if (list && list.value1) {
      //   let all = list.value1
      //   let arr = []
      //   let realBore = ''
       
      //     arr = all.split('*')
      //     realBore = arr[0] - arr[1] * 2
      //     this.$set(this.form, 'thickness', arr[1])
      //     this.$set(this.form, 'innerDiameter', realBore)
      //     this.$set(this.form, 'outerDiameter', arr[0])
          
        
      // }
    },
    diameterChge(val) {
      this.$set(this.form, 'thickness', null)
      this.$set(this.form, 'innerDiameter', null)
      this.$set(this.form, 'outerDiameter', null)
      this.getRealBore()
    },
    thicknessChange() {
      this.getRealBore()
    },
    openDialog(data) {
      if (data.addOrEdit == 'edit') {
        // 编辑
        this.addOrEdit = data.addOrEdit
        //  this.pipeTypeChange(data.pipeType)

        this.getCodeName('edit', data.pipeType)
        this.form = {
          ...data
        }
        this.pipeLengthTotal = data.pipeLength
      } else {
        this.getCodeName()
        // 新增
        this.lineArr = data.lineArr
        this.pipeLengthTotal = data.pipeLength
        this.endPointObj = data.endPointObj
        this.startPointObj = data.startPointObj
      }
      this.dialogVisible = true
    },
    getCodeName(type, value) {
      let that = this
      let code =
        commonDict[100023].code +
        ',' +
        commonDict[100024].code +
        ',' +
        commonDict[100028].code +
        ',' +
        commonDict[100029].code +
        ',' +
        commonDict[100004].code +
        ',' +
        commonDict[30011].code
        

      findDictTree({ dictCode: code }).then(res => {
        if (res.data) {
          let pipeTypeData = res.data[commonDict[100023].code]
          that.pipeTypeList = pipeTypeData

          // that.diameterData = res.data[commonDict[100024].code]
          that.pressureLevelList = serverMap[1006]
          that.gasTypeList = serverMap[1008]
          that.pipeStatusList = serverMap[1007]
          that.standardList = res.data[commonDict[100028].code]
          that.antisepticTypeList = res.data[commonDict[100029].code]
          that.buildTypeList = res.data[commonDict[100004].code]
          that.confirmStatusList = res.data[commonDict[30011].code]
          if (type == 'edit') {
            this.pipeTypeChange(value, type)
          } else {
            that.form.pipeType = pipeTypeData[0].dictCode
            that.pipeModelList = pipeTypeData[0].children
            //
            this.selectDef()
             that.setDiameterList1(pipeTypeData[0].linkCode)
          }
        }
      })
    },
    pipeTypeChange(value, type) {
      if (type != 'edit') {
        this.form.pipeModel = ''
        this.form.diameter = ''
        this.pipeModelList = []
        this.diameterList = []
        this.$set(this.form, 'thickness', null)
        this.$set(this.form, 'realBore', null)
        this.form.roughness = value == '1000230002' ? 0.01 : 0.1
       value = this.form.pipeType 
      }
      for (let item of this.pipeTypeList) {
        if (item.dictCode == value) {
          this.pipeModelList = item.children
            this.setDiameterList(item.linkCode)
          break
        }
      }
      
    },
    setDiameterList1(linkCode){
      selectDictListByPid({pid:linkCode}).then(res=>{
        this.diameterList = res.data
        // this.form.diameter = this.diameterList[0].code
        // this.$refs.form.validateField('diameter')
        // this.getRealBore()
      })
    }
    ,
    setDiameterList(linkCode) {
      selectDictListByPid({pid:linkCode}).then(res=>{
        this.diameterList = res.data
      })
    },
    close() {
      this.dialogVisible = false
      this.$parent.$parent.editTool.close(true)
      this.$parent.$parent.lineHighlightRemove()
      if (this.addOrEdit == 'add') {
        this.$parent.startDraw('line')
        this.selectDef()
      }
    },
    selectDef() {
      let that = this
      // that.form = {
      //   pipeType: that.pipeTypeList[0].dictCode,
      //   // pipeModel: that.pipeModelList[0].dictCode,
      //   // diameter: that.diameterList[0].dictCode,
      //   pressureLevel: that.pressureLevelList[0].dictCode,
      //   gasType: that.gasTypeList[0].dictCode,
      //   pipeStatus: that.pipeStatusList[0].dictCode,
      //   confirmStatusCode: that.confirmStatusList[0].dictCode,
      //   buildType: that.buildTypeList[0].dictCode,
      //   roughness: 0.1,
      //   buildDate: formatTime('yyyy-MM-dd', new Date()),
      //   companyName: getCompanyName(),
      //   deptId: getCompanyId()
      // }
      that.form.pipeType = that.pipeTypeList[0].dictCode
      that.form.pressureLevel = that.pressureLevelList[0].dictCode
      that.form.gasType = that.gasTypeList[0].dictCode
      that.form.pipeStatus = that.pipeStatusList[0].dictCode
       that.form.confirmStatusCode = that.confirmStatusList[1].dictCode
      that.form.buildType = that.buildTypeList[0].dictCode
      that.form.roughness = 0.1
      that.form.buildDate =  formatTime('yyyy-MM-dd', new Date())
      that.form.layerName = that.$store.getters.currentLayerName
   
      
    },
    update() {
      let that = this
      that.$refs.form.validate(valid => {
        if (valid) {
          pipeUpdate(that.form)
            .then(res => {
              if (res.code == 200) {
                that.$message.success(res.msg)
                that.$eventBus.$emit('setFilter')
                that.close()
              } else {
                that.$message.error(res.msg)
              }
            })
            
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
     submit() {
      let that = this

      that.$refs.form.validate(valid => {
        if (valid) {
          let form = []

          that.lineArr.forEach(el => {
            console.log(el)
            let obj = {
              layerId: that.$store.getters.currentLayer,
              ...that.form,
              ...el
            }
            form.push(obj)
          })
          form[0].startPointObj = that.startPointObj.pipeId ? that.startPointObj : null
          form[form.length - 1].endPointObj = that.endPointObj.pipeId ? that.endPointObj : null
          if(form[form.length - 1]&&form[form.length - 1].endPointObj){
            form[form.length - 1].endPointObj.nodeType = 0
          }
          if(form[0]&&form[0].startPointObj){
            form[0].startPointObj.nodeType = 0
          }
          
          saveLine(form)
            .then(res => {
              if (res.code == 200) {
                that.$message.success(res.msg)
                that.$eventBus.$emit('setFilter')
                that.close()
              } else {
                that.$message.error(res.msg)
              }
            })
            .catch(err => {
              that.$message.error(err)
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    resetForm() {
      this.$refs.form.resetFields()
    }
  }
}
</script>
<style lang="scss" scoped>
.btn {
  text-align: right;
  button {
    margin: 7px 15px 0 15px;
  }
}
.form-box {
  .form {
    display: flex;
    justify-content: space-between;
    flex-flow: wrap;
    padding: 0px 10px;
  }
}
</style>
